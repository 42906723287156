import {ActionContext, createStore} from 'vuex'
import USER from "@/interfaces/user";
import PRODUCT from "@/interfaces/product";
import CATEGORY from "@/interfaces/category";
import apiService from "@/api/api";

export interface State {
  menu: any,
  user: USER | null,
  categories: Array<CATEGORY>,
  products: Array<PRODUCT>,
  users: Array<USER>,
  initialized: boolean,
  notification: {type: string, text: string},
  lang: string
}

function initOnLogin(context: ActionContext<any, any>, payload?: {type: string, text: string}) {
  const stepsToComplete = 2
  let completed = 0
  const incrementCompleted = function () {
    completed++
    context.state.initialized = completed === stepsToComplete
  }
  apiService.logIn(payload).then((response: any): any => {
    // console.log(response.data)
    if (response.data.error) {

      context.state.initialized = true
      return
    }
    const user = response.data.user
    if (user) {
      context.commit('user', user)
      localStorage.setItem('jsa_sTok', user.session_token)
      localStorage.setItem('jsa_sExp', user.session_expires)
      incrementCompleted()
      if (response.data.menu) {
        context.commit('menu', response.data.menu)
        incrementCompleted()
      }
      if (response.data.categories) context.commit('categories', response.data.categories)
      if (response.data.products) context.commit('products', response.data.products)
    }
  }).catch((e: any): any => {
    // this.notify({
    //   type: 'danger',
    //   text: e.message
    // });
  });

  return
}

export default createStore<State>({
  state: {
    menu: {},
    user: null,
    categories: [],
    products: [],
    users: [],
    initialized: false,
    notification: {type: 's', text: ''},
    lang: 'ua'
  },
  getters: {
    MENU: state => {
      const menu: any = {};
      const keys = Object.keys(state.menu);
      for (let i = 0; i < keys.length; i++) {
        menu[keys[i]] = {title: state.menu[keys[i]].title, submenu: {}};
        const subKeys = Object.keys(state.menu[keys[i]].submenu);
        for (let j = 0; j < subKeys.length; j++) {
          menu[keys[i]].submenu[subKeys[j]] = state.menu[keys[i]].submenu[subKeys[j]];
          if (j === subKeys.length - 1 && i === keys.length - 1) return menu;
        }
        if (i === keys.length - 1) return menu;
      }
    },
    AUTHORIZED: state => {
      // console.log('AUTH: ', state.user)
      return state.user != null && state.user.id > 0
    },
    USER: state => { return state.user },
    CATEGORIES: state => { return state.categories },
    PRODUCTS: state => { return state.products },
    NOTIFICATION: state => { return state.notification },
    LANG: state => { return state.lang }
  },
  mutations: {
    menu(state, payload) {
      if (payload != null) {
        state.menu = payload
        if (state.user != null) state.initialized = true
      }
    },
    user(state, payload) {
      if (payload == null) state.initialized = false
      state.user = payload
    },
    categories(state, payload) { if (payload != null) state.categories = payload },
    products(state, payload) { if (payload != null) state.products = payload },
    notify(state, payload: {type: string, text: string}) {
      // console.log('Store notify!!!')
      state.notification = payload
    }
  },
  actions: {
    init(context) {
      if (localStorage.jsa_sTok) {
        initOnLogin(context)
      }
    },
    initCompleted(context): Promise<boolean> {
      return new Promise<boolean>(resolve => {
        if (!context.state.initialized) {
          if (context.getters.AUTHORIZED && Object.keys(context.state.menu).length > 0) {
            context.state.initialized = true
            resolve(true)
          } else {
            let attempts = 0
            const i = setInterval(() => {
              if (context.state.initialized || attempts > 100) {
                clearInterval(i)
                resolve(attempts <= 100 && context.state.initialized)
              }
              attempts++
            }, 100)
          }
        } else resolve(true)
      })
    },
    userLogIn(context, payload?: {email: string, password: string}): Promise<any> {
      return new Promise<any>(resolve => {
        resolve(null)
      })
    },
    userLoggedOut(context) {
      localStorage.removeItem('jsa_sTok')
      localStorage.removeItem('jsa_sExp')
      context.commit('user', null)
    },
    addData(context, payload) {
      switch (payload.key) {
        case 'p':
          context.state.products.push(payload.data)
          break
        case 'c':
          context.state.users.push(payload.data)
          break
        case 'u':
          context.state.users.push(payload.data)
          break
      }
    },
    notify(context, payload: {type: string, text: string}) {
      context.commit('notify', payload)
    },
    changeLang(context) {
      context.state.lang = context.state.lang === 'ua' ? 'en' : 'ua'
    },
    greeting(context) {
      if (!context.state.user) return
      let greeting = 'Biтаю'
      let welcomeText = ''
      const haveAGoodTimeGreeting = function (): string {
        const currHour = new Date().getHours()
        return `${currHour >= 22 || currHour < 5 ? 'ї ночi! 🌙' : currHour >= 5 && currHour < 8 ?
            'го ранку!' : currHour >= 18 ? 'го вечора!' : 'го дня! 🌞'}`
      }
      if (context.state.user.name === 'AnnaDinara' && Math.floor(Math.random() * 2) > 0) {
        const greets = ['Привiтулi, ','Привiтик, ','Вiтаю, панi ']
        greeting = `${greets[Math.floor(Math.random() * greets.length)]}${context.state.user.name}! 👻<br>`
        const compliments = [
          `Бажаю тобi гарно${haveAGoodTimeGreeting()}`,
          'Сьогоднi, як i завжди, гарно виглядаєш! 😉',
          'Ти неймовiрна дiвчина! 😉',
          'Дуже приємно знов тебе бачити! 😊'
        ]
        welcomeText = greeting + compliments[Math.floor(Math.random() * compliments.length)]
      } else {
        welcomeText = `${greeting}, ${context.state.user.name}!<br>Бажаю добро${haveAGoodTimeGreeting()}`
      }
      context.commit('notify', {type: 's', text: welcomeText})
    }
  },
  modules: {
  }
})
